import React from 'react';
import throttle from '../../utils/throttle';
import { connect } from 'react-redux';
import { selectBlock, selectFlat } from '../../store/actions';

class THCanvas extends React.Component {
  canvas = React.createRef();

  handleHover = throttle(e => this.processCanvasDraw(this.getMousePosition(e)), 40);
  handleMouseLeave = e => this.clearCanvas(this.getMousePosition(e));



  getMousePosition = e => {
    const { left, top } = e.target.getBoundingClientRect();

    return {
      Xpx: e.pageX - left,
      Ypx: e.pageY - window.pageYOffset - top
    };
  };



  handleClick = e => {
    const selectedFlat = this.processCanvasDraw(this.getMousePosition(e));
    let scrollDestination;

    if (selectBlock && selectedFlat) {
      if (selectedFlat === 'left' || selectedFlat === 'right') {
        this.props.selectFlat(selectedFlat);
        scrollDestination = document.querySelector('.flats-plans').offsetTop;
      }
      else {
        this.props.select(selectedFlat);
        scrollDestination = document.getElementById('apartments').offsetTop;
      }
    }

    window.scrollTo({
      left: 0,
      top: scrollDestination,
      behavior: 'smooth'
    });
  };



  buildMainArea = (ctx, width, height) => {
    ctx.fillStyle = 'transparent';
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(width, 0);
    ctx.lineTo(width, height);
    ctx.lineTo(0, height);
    ctx.lineTo(0, 0);
    ctx.closePath();
    ctx.fill();
  };



  clearCanvas = ({ elWidth, elHeight }) => {
    const canvas = this.canvas.current;
    const dpi = window.devicePixelRatio;
    canvas.setAttribute('width', elWidth * dpi);
    canvas.setAttribute('height', elHeight * dpi);
    canvas.getContext('2d').clearRect(0, 0, elWidth * dpi, elHeight * dpi);
  };



  processCanvasDraw = ({ Xpx, Ypx }) => {
    const canvas = this.canvas.current;
    const dpi = window.devicePixelRatio;
    const computedStyle = getComputedStyle(canvas);
    const width = +computedStyle.width.slice(0, -2) * dpi;
    const height = +computedStyle.height.slice(0, -2) * dpi;
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    let selectedTH = null;

    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 6;
    ctx.strokeStyle = '#cbb85e';
    ctx.clearRect(0, 0, width, height);

    this.buildMainArea(ctx, width, height);

    ctx.fillStyle = 'rgba(0, 0, 0, .2)';
    if (ctx.isPointInPath(Xpx * dpi, Ypx * dpi)) {
      ctx.fillRect(0, 0, width, height);
    }

    this.props.coordinates.forEach(flat => {
      ctx.beginPath();
      flat.points.forEach(([x, y], idx) => {
        idx === 0
          ? ctx.moveTo(x * width, y * height)
          : ctx.lineTo(x * width, y * height)
      });

      ctx.fillStyle = 'rgba(203, 184, 94, .4)';
      ctx.globalCompositeOperation = 'destination-out';

      if (ctx.isPointInPath(Xpx * dpi, Ypx * dpi)) {
        ctx.fill();
        ctx.globalCompositeOperation = 'source-over';
        ctx.fill();
        ctx.stroke();

        selectedTH = flat.wing || flat.type;
      }

      ctx.closePath();
    });

    return selectedTH;
  }



  render() {
    return (
      <canvas
        className="townhouses-canvas"
        ref={this.canvas}
        onMouseDown={this.handleClick}
        onMouseMove={this.handleHover}
        onMouseLeave={this.handleMouseLeave} >
        Пожалуйста используйте более современный браузер
      </canvas>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  select: th => dispatch(selectBlock(th)),
  selectFlat: f => dispatch(selectFlat(f))
});

export default connect(null, mapDispatchToProps)(THCanvas);
