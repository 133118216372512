export default function throttle(func, wait) {
  let lastCall = 0;
  let ignore = false;
  return function() {
    const now = Date.now();
    if (now > (lastCall + wait)) {
      lastCall = now;

      if (!ignore) {
        func.apply(this, arguments);
        ignore = true;
        setTimeout(() => ignore = false, 50)
      } 

      return;
    }
    else {
      try { arguments[0].preventDefault() }
      catch (e) { return };
    };
  }
}