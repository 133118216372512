import React, { Component } from 'react';
import { LineBottom } from '../lines';
import PlanSelection from './plan-selection';
import Layout from '../layout';
import Container from '../container';

class FlatsPlans extends Component {
  render() {
    return (
      <Layout className="flats-plans section-bg-light">
        <LineBottom style={{ bottom: 70 }} />

        <Container>
          <div className="content">
            <div className="info">
              <div className="titles">
                <h2>Выберите планировку</h2>

                {/* <p> */}
                {/*   Благодаря удачному расположению комплекса все квартиры имеют хорошее естественное освещение и отличный вид из окна. Подсветка фасада в ночное время создаст уют европейского городка. */}
                {/* </p> */}
                {/* <p>Для просмотра более детальной информации, выберите интересующую вас квартиру на изображении рядом.</p> */}

                <LineBottom />
              </div>

              <span>DRESDEN</span>
            </div>

            <PlanSelection />
          </div>
        </Container>
      </Layout>
    )
  }
}

export default FlatsPlans;
