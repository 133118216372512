import '../styles/index.scss';
import React, { Component } from "react";
import SEO from "../components/seo";
import THHero from '../components/th-hero';
import FlatsPlans from '../components/flats-plans';
// import FlatDetails from '../components/flats-details';
import Footer from '../components/footer';
import { graphql } from 'gatsby';
import { connect } from "react-redux";

const subheader = "Две 2-х этажных секции мы наполнили идеальными квартирами от 32 м2. Максимум комфорта вашему проживанию в ЖК Dresden добавит собственная парковка на закрытой охраняемой территории  Кроме того, в комплексе запланирована парковка открытого типа на 60 машиномест. Бассейн с оборудованной зоной отдыха, детская площадка с безопасным покрытием, круглосуточная охрана и видеонаблюдение - все эти опции сделают ваше проживание в комплексе беспрецедентно комфортным и безопасным!";

class Flats extends Component {
  componentDidMount() {
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    if (bodyOverflow === 'hidden') document.documentElement.style.overflow = 'visible';
    const offset = document.querySelector('.hero').clientHeight;
    window.scrollTo({
      top: offset,
      left: 0,
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <div className="flats-page plans-page page">
        <SEO title="Квартиры" />

        <THHero
          heading="КВАРТИРЫ"
          paragraph={subheader}
          coordinates={this.props.coordinates}
          bgImg={this.props.data.bg.childImageSharp.fluid}
          bigBtnText="Выбрать таунхаусы"
          bigBtnLink="/townhouses"
        />
        <FlatsPlans />
        {/* <FlatDetails /> */}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = ({ flats }) => ({ coordinates: flats });
export default connect(mapStateToProps)(Flats);

export const pageQuery = graphql`
  query {
    bg: file(relativePath: { eq: "bg/townhouses_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
