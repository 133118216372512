import React, { Component } from 'react';
import throttle from '../../utils/throttle';
import { connect } from 'react-redux';
import { selectFlat } from '../../store/actions';
import resetIcon from '../../images/img/reset.svg';

class PlanSelection extends Component {
  canvasRef = React.createRef();

  state = { resetButtonActive: false };

  handleHover = throttle(e => this.processCanvasDraw(this.getMousePosition(e)), 40);
  handleMouseLeave = e => this.clearCanvas(this.getMousePosition(e));



  componentDidMount() {
    if (this.props.selectedFlat)
      this.setState({ resetButtonActive: true })
  }



  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selectedFlat && this.props.selectedFlat)
      this.setState({ resetButtonActive: true })
  }



  handleClick = e => {
    const selectedFlat = this.processCanvasDraw(this.getMousePosition(e));

    if (selectFlat && selectedFlat) {
      this.props.select(selectedFlat);
      this.setState(prev => ({ resetButtonActive: !prev.resetButtonActive }));
    }
  };



  handleResetButtonClick = _e => {
    this.props.select(null);
    this.setState(prev => ({ resetButtonActive: !prev.resetButtonActive }));
  }



  processCanvasDraw = ({ Xpx, Ypx }) => {
    const canvas = this.canvasRef.current;
    const dpi = window.devicePixelRatio;
    const width = +getComputedStyle(canvas).getPropertyValue('width').slice(0, -2) * dpi;
    const height = +getComputedStyle(canvas).getPropertyValue('height').slice(0, -2) * dpi;
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    let selectedFlat = null;

    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 6;
    ctx.strokeStyle = '#cbb85e';
    ctx.clearRect(0, 0, width, height);

    this.buildMainArea(ctx, width, height);

    ctx.fillStyle = 'rgba(0, 0, 0, .8)';

    if (ctx.isPointInPath(Xpx * dpi, Ypx * dpi))
      ctx.fillRect(0, 0, width, height);

    this.props.flatsCoordinates.forEach(flat => {
      ctx.beginPath();
      flat.points.forEach(([x, y], idx) => {
        const X = x * width + (x > flat.center.x ? -ctx.lineWidth : ctx.lineWidth);
        const Y = y * height + (y > flat.center.y ? -ctx.lineWidth : ctx.lineWidth);

        idx === 0
          ? ctx.moveTo(X, Y)
          : ctx.lineTo(X, Y);
      });

      if (ctx.isPointInPath(Xpx * dpi, Ypx * dpi)) {
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fill();
        ctx.globalCompositeOperation = 'source-over';
        ctx.stroke();
        ctx.closePath();

        selectedFlat = flat.flat;
      }
    });

    return selectedFlat;
  }



  getMousePosition = e => {
    const { left, top } = e.target.getBoundingClientRect();

    return {
      Xpx: e.pageX - left,
      Ypx: e.pageY - window.pageYOffset - top
    };
  }



  buildMainArea = (ctx, width, height) => {
    ctx.fillStyle = 'transparent';
    ctx.beginPath();
    ctx.moveTo(0, 0.170 * height);
    ctx.lineTo(width, 0.170 * height);
    ctx.lineTo(width, 0.920 * height);
    ctx.lineTo(0, 0.920 * height);
    ctx.lineTo(0, 0.170 * height);
    ctx.closePath();
    ctx.fill();
  }



  clearCanvas = ({ elWidth, elHeight }) => {
    const canvas = this.canvasRef.current;
    const dpi = window.devicePixelRatio;
    canvas.setAttribute('width', elWidth * dpi);
    canvas.setAttribute('height', elHeight * dpi);
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, elWidth, elHeight);
  }



  render() {
    const { selectedFlat } = this.props;
    const { resetButtonActive } = this.state;

    return (
      <div className="plans-selection">
        <div style={{ position: 'relative' }}>
          <img
            src={ `/plans/flats/${selectedFlat === null ? 'full' : selectedFlat}.png` }
            alt="flats' plans" />

          { selectedFlat === null
            && (
              <canvas
                ref={this.canvasRef}
                onMouseDown={this.handleClick}
                onMouseMove={this.handleHover}
                onMouseLeave={this.handleMouseLeave} >
                Пожалуйста используйте более современный браузер
              </canvas>
            ) }
        </div>

        <button
          className={`reset-button ${resetButtonActive ? 'active' : ''}`}
          onClick={resetButtonActive ? this.handleResetButtonClick : null}>
          <img src={resetIcon} alt="" />
        </button>
      </div>
    )
  }
}

const mapStateToProps = ({ flatsCoordinates, selectedFlat }) => ({
  flatsCoordinates, selectedFlat
});

const mapDispatchToProps = dispatch => ({
  select: flat => dispatch(selectFlat(flat))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanSelection);
