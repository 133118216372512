import React, { useState } from 'react';
import Header from '../header';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Container from '../container';
import THCanvas from '../th-canvas';

const handleNavClick = () =>
  window.scrollTo({
    left: 0,
    top: 10000,
    behavior: 'smooth'
  });

const Titles = ({ heading, paragraph, bigBtnText, bigBtnLink }) => (
  <div className="titles">
    <h1>{ heading }</h1>

    <p>{ paragraph }</p>

    <div className="buttons">
      <Link to="/contactus">
        <button className="btn-big">Связаться с нами</button>
      </Link>

      <Link to={bigBtnLink} className="extra-big-btn">
        <button className="btn-big">{ bigBtnText }</button>
      </Link>
    </div>
  </div>
);

const THHero = ({
  bgImg,
  coordinates,
  heading,
  paragraph,
  bigBtnText,
  bigBtnLink
}) => {
  const [ menuBgVisible, setMenuVisible ] = useState(false);

  const toggleBg = () => {
    setMenuVisible(!menuBgVisible);
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    document.documentElement.style.overflow = bodyOverflow === 'hidden'
      ? 'visible'
      : 'hidden';
  }

  return (
    <section className="hero full-screen section-bg-dark">
      <div className={`menu-bg ${menuBgVisible ? "active" : ""}`} />

      <Header toggleBg={toggleBg} active={menuBgVisible} />

      <Container>
        <div className="content">
          <Titles
            heading={heading}
            paragraph={paragraph}
            bigBtnText={bigBtnText}
            bigBtnLink={bigBtnLink} />

          <div className="bg">
            <Img fluid={bgImg} alt="background" />
            <THCanvas coordinates={coordinates} />
          </div>

          <div className="learn-more" onClick={handleNavClick}>
            <img src="/icons/details_icon.svg" alt="learn more" />
            <span>Свяжитесь с нами</span>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default THHero;
